import React from "react"
import styled from "@emotion/styled"
import theme from "../shared/theme"

const Button = ({ children, title, ariaLabel, linkTo }) => {
  return (
    <React.Fragment>
      <StyledAnchor
        href={linkTo}
        title={title}
        aria-label={ariaLabel}
        tabIndex={0}
      >
        <svg>
          <rect x="0" y="0" fill="none" width="100%" height="100%" />
        </svg>
        {children}
      </StyledAnchor>
    </React.Fragment>
  )
}

const StyledAnchor = styled.a`
  font-family: ${theme.fontFamily};
  color: ${theme.colors.darkFont};
  font-size: ${theme.fontsizes.smallTextMediumScreen};
  padding: calc(1px + ${theme.spaces.buttonPaddingTopBigScreen})
    ${theme.spaces.buttonPaddingSideBigScreen}
    ${theme.spaces.buttonPaddingTopBigScreen}
    ${theme.spaces.buttonPaddingSideBigScreen};
  position: relative;
  text-decoration: none;

  svg {
    height: ${theme.buttonSizes.projectButton};
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  //für rückwärts Animation nach dem Hover
  rect {
    stroke: ${theme.colors.darkFont};
    stroke-width: 3;
    stroke-dasharray: 500, 0;
    transition: all 1s linear;
  }

  &:hover {
    font-weight: 600;
  }

  &:hover rect {
    stroke-width: calc(3 * ${theme.lineStrength.buttons});
    stroke-dasharray: 0, 500; //erste Zahl definiert wieviel Rest stehen bleibt, zweite die Länge des animierten Strichs (zu kurz ergibt mehrere)
    transition: all 2s cubic-bezier(0.1, 1, 0.3, 1);
  }

  @media all and (max-width: 768px) {
    font-size: ${theme.fontsizes.smallTextSmallScreen};
    padding: ${theme.spaces.buttonPaddingTopSmallScreen}
      ${theme.spaces.buttonPaddingSideSmallScreen};
    border-radius: 0;
    border: ${theme.lineStrength.buttons} solid ${theme.colors.darkFont};
    font-family: ${theme.fontFamily};

    &:hover {
      background-color: ${theme.colors.darkFont};
      color: ${theme.colors.bodyBackground};
    }

    svg {
      display: none;
    }

    rect {
      display: none;
    }
  }
`

export default Button
